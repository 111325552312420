  
  .footer {
    align-items: center;
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    gap: 24px;
    left: 0;
    padding: 40px 0px 80px;
    position: absolute;
    top: 2084px;
    width: 1440px;
  }
  
  .footer .content  {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 1279.94px;
  }
  
  .footer .content-left {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 31px;
    position: relative;
  }
  
  .footer .logo-1 {
    height: 30px !important;
    position: relative !important;
    width: 114px !important;
  }
  
  .footer .images-container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
  }
  
  /* .footer .footer-links {
    display: inline-flex;
  } */
  
  
  .footer .img {
    height: 55.53px;
    position: relative;
    width: 55.53px;
  }
  
  .footer .image {
    height: 35.88px;
    position: relative;
    width: 100.9px;
  }
  
  .footer .layer {
    height: 31px;
    position: relative;
    width: 71px;
  }
  
  .footer .content-right {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
  }
  
  .footer .social-media-frame {
    /* display: flex; */
    flex: 0 0 auto;
    position: relative;
  }
  
  .footer .social-media-frame .social-media-icon{
    margin-right: 10px;
  }
  .footer .images-container .text-wrapper-4 {
    color: #ffffff;
    font-family: var(--paragraph-r-font-family);
    font-size: var(--paragraph-r-font-size);
    font-style: var(--paragraph-r-font-style);
    font-weight: var(--paragraph-r-font-weight);
    letter-spacing: var(--paragraph-r-letter-spacing);
    line-height: var(--paragraph-r-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
    margin: 0;
  }

  .footer .images-container .text-wrapper-4 a {
    color: #ffffff;
  }

  
  .footer .content-right-links {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 103px;
  }
  
  .footer .made-with-by-raw {
    color: #ffffff;
    font-family: "Alliance No.2-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .footer .made-with-by-raw .span {
    color: #ffffff;
    font-family: var(--paragraph-r-font-family);
    font-size: var(--paragraph-r-font-size);
    font-style: var(--paragraph-r-font-style);
    font-weight: var(--paragraph-r-font-weight);
    letter-spacing: var(--paragraph-r-letter-spacing);
    line-height: var(--paragraph-r-line-height);
  }
  
  .footer .made-with-by-raw .raw-text a{
    text-decoration: underline;
    color: #ffffff
  }
  
@media only screen and (max-width: 1440px){
  .footer {
    width: 100% !important;
    padding: 40px 25px 80px !important;
  }
  
  .footer .content  {
    width: 100%;
  }
}
@media only screen and (max-width: 992px){
  .footer {
    display: block !important;
  }
  .footer .content {    
    flex-direction: column;
    gap: 64px;
  }
  .footer .content-right {
    align-items: flex-start;
    gap: 8px;
  }
  .footer .social-media-frame {
    margin-bottom:10px;
  }
  .resultsContainer .footer {
    top:100px !important;
  }
}


@media only screen and (max-width: 539px){
  .footer .footer-links {    
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
}

:root {
  --black: rgba(14, 1, 25, 1);
  --body-font-family: "Manrope-Regular", Helvetica;
  --body-font-size: 16px;
  --body-font-style: normal;
  --body-font-weight: 400;
  --body-letter-spacing: 0px;
  --body-line-height: 150%;
  --button-font-family: "InstrumentSans-SemiBold", Helvetica;
  --button-font-size: 14px;
  --button-font-style: normal;
  --button-font-weight: 600;
  --button-letter-spacing: 0px;
  --button-line-height: 150%;
  --detail-font-family: "Manrope-Regular", Helvetica;
  --detail-font-size: 12px;
  --detail-font-style: normal;
  --detail-font-weight: 400;
  --detail-letter-spacing: 0px;
  --detail-line-height: 150%;
  --fucshia: rgba(196, 29, 255, 1);
  --green-glow: 0px 0px 10.628571510314941px 2.3619048595428467px rgba(156, 255, 29, 1);
  --hover: 0px 0px 41px 0px rgba(196, 29, 255, 0.5);
  --menu-item-font-family: "InstrumentSans-Regular", Helvetica;
  --menu-item-font-size: 14px;
  --menu-item-font-style: normal;
  --menu-item-font-weight: 400;
  --menu-item-letter-spacing: 0px;
  --menu-item-line-height: 150%;
  --paragraph-l-font-family: "Manrope-Regular", Helvetica;
  --paragraph-l-font-size: 16px;
  --paragraph-l-font-style: normal;
  --paragraph-l-font-weight: 400;
  --paragraph-l-letter-spacing: 0px;
  --paragraph-l-line-height: 150%;
  --paragraph-r-font-family: "AllianceNo.2-Regular", Helvetica;
  --paragraph-r-font-size: 12px;
  --paragraph-r-font-style: normal;
  --paragraph-r-font-weight: 400;
  --paragraph-r-letter-spacing: 0px;
  --paragraph-r-line-height: 150%;
  --purple: rgba(37, 19, 86, 1);
  --red-glow: 0px 0px 10.628571510314941px 2.3619048595428467px rgba(223, 48, 48, 1);
  --subheading-1-font-family: "Manrope-Bold", Helvetica;
  --subheading-1-font-size: 16px;
  --subheading-1-font-style: normal;
  --subheading-1-font-weight: 700;
  --subheading-1-letter-spacing: 0px;
  --subheading-1-line-height: 150%;
  --subheading-2-font-family: "Manrope-Bold", Helvetica;
  --subheading-2-font-size: 14px;
  --subheading-2-font-style: normal;
  --subheading-2-font-weight: 700;
  --subheading-2-letter-spacing: 0px;
  --subheading-2-line-height: 150%;
  --textheading: rgba(255, 255, 255, 1);
  --textmain-dark-mode: rgba(231, 227, 236, 1);
  --textmain-light-mode: rgba(14, 1, 25, 1);
  --textwhite: rgba(255, 255, 255, 1);
  --title-1-font-family: "InstrumentSans-Medium", Helvetica;
  --title-1-font-size: 72px;
  --title-1-font-style: normal;
  --title-1-font-weight: 500;
  --title-1-letter-spacing: 0px;
  --title-1-line-height: 110.00000238418579%;
  --title-1-mobile-font-family: "InstrumentSans-Medium", Helvetica;
  --title-1-mobile-font-size: 50px;
  --title-1-mobile-font-style: normal;
  --title-1-mobile-font-weight: 500;
  --title-1-mobile-letter-spacing: 0px;
  --title-1-mobile-line-height: 110.00000238418579%;
  --title-2-font-family: "InstrumentSans-Medium", Helvetica;
  --title-2-font-size: 52px;
  --title-2-font-style: normal;
  --title-2-font-weight: 500;
  --title-2-letter-spacing: 0px;
  --title-2-line-height: 110.00000238418579%;
  --title-2-mobile-font-family: "InstrumentSans-Medium", Helvetica;
  --title-2-mobile-font-size: 40px;
  --title-2-mobile-font-style: normal;
  --title-2-mobile-font-weight: 500;
  --title-2-mobile-letter-spacing: 0px;
  --title-2-mobile-line-height: 110.00000238418579%;
  --title-3-font-family: "AllianceNo.2-Medium", Helvetica;
  --title-3-font-size: 40px;
  --title-3-font-style: normal;
  --title-3-font-weight: 500;
  --title-3-letter-spacing: 0px;
  --title-3-line-height: 120.00000476837158%;
  --title-3-mobile-font-family: "InstrumentSans-Medium", Helvetica;
  --title-3-mobile-font-size: 32px;
  --title-3-mobile-font-style: normal;
  --title-3-mobile-font-weight: 500;
  --title-3-mobile-letter-spacing: 0px;
  --title-3-mobile-line-height: 110.00000238418579%;
  --title-4-font-family: "InstrumentSans-SemiBold", Helvetica;
  --title-4-font-size: 32px;
  --title-4-font-style: normal;
  --title-4-font-weight: 600;
  --title-4-letter-spacing: 0px;
  --title-4-line-height: 129.99999523162842%;
  --title-4-mobile-font-family: "InstrumentSans-SemiBold", Helvetica;
  --title-4-mobile-font-size: 24px;
  --title-4-mobile-font-style: normal;
  --title-4-mobile-font-weight: 600;
  --title-4-mobile-letter-spacing: 0.48px;
  --title-4-mobile-line-height: 129.99999523162842%;  
}

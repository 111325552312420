.CTA-banner {
  height: 529px;
  width: 1384px;
}

.CTA-banner-instance {
  left: 40px !important;
  position: absolute !important;
  top: 1446px !important;
}

.CTA-banner .frame {
  align-items: center;
  background: linear-gradient(180deg, rgb(14, 0, 25) 0%, rgb(38.02, 17.04, 66.34) 45.34%, rgb(100, 61, 173) 100%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 200px 40px;
  position: relative;
  width: 1384px;
}

.CTA-banner .div {
  color: var(--textmain-dark-mode);
  font-family: var(--title-3-font-family);
  font-size: var(--title-3-font-size);
  font-style: var(--title-3-font-style);
  font-weight: var(--title-3-font-weight);
  letter-spacing: var(--title-3-letter-spacing);
  line-height: var(--title-3-line-height);
  margin-top: -0.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.CTA-banner .primary-button-instance {
  flex: 0 0 auto !important;
}
  
@media only screen and (max-width: 1440px){
  .CTA-banner .frame {
    width: 100%
  }
  .CTA-banner-instance {
    top: 1346px !important;
    left: unset !important;
    width: 98%;
  }
}

@media only screen and (max-width: 767px){
  .CTA-banner .div {    
    white-space: normal;
    color: #ffffff;
    font-family: var(--title-3-mobile-font-family);
    font-size: var(--title-3-mobile-font-size);
    font-style: var(--title-3-mobile-font-style);
    font-weight: var(--title-3-mobile-font-weight);
    letter-spacing: var(--title-3-mobile-letter-spacing);
    line-height: var(--title-3-mobile-line-height);
    margin-top: -0.5px;
    position: relative;
    text-align: center;
    width: 250px;
  }
}

.indexLoader {
  background-color: #0e0119;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.indexLoader .overlap-group-wrapper {
  background-color: #0e0119;
  width: 1440px;
}

.indexLoader .overlap-group {
  position: absolute;
  width: 331px;
  left: calc(50% - 331px / 2 + 0.5px);
  top: 33.33%;
  bottom: 43.44%;
}

.indexLoader .loading-spinner {
  height: 183px !important;
  left: 75px !important;
  position: absolute !important;
  top: 0 !important;
  width: 183px !important;
}

.indexLoader .checking-to-see-if {
  color: var(--textheading);
  font-family: var(--subheading-1-font-family);
  font-size: var(--subheading-1-font-size);
  font-style: var(--subheading-1-font-style);
  font-weight: var(--subheading-1-font-weight);
  left: 0px;
  letter-spacing: var(--subheading-1-letter-spacing);
  line-height: var(--subheading-1-line-height);
  position: absolute;
  text-align: center;
  top: 210px;
  width: auto;
}

.image {
  position: absolute;
}

.image img {
  position: absolute;
}

.spin {
  position: relative;
  display: inline-block;
  width: 183px;
  height: 183px;
  border: 2px solid #FFFFFF1A;
  border-radius: 100%;
  animation: spin 0.75s infinite linear;
  box-sizing: border-box;
  perspective: 100px;
}

.spin::before,
.spin::after {
  left: -2px;
  top: -2px;
  position: absolute;
  content: "";
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner,
.spinner::before,
.spinner::after {
  display: inline-block;
  border-color: transparent;
  animation-duration: 1s;
}

.spinner::after {
  border-bottom-color:#FFFFFF1A;
  border-left-color:grey ;
  border-top-color: #FFF;
  border-right-color: #7766661a;
}

@media (max-width: 480px) {
  .spin {
    width: 270px;
    height: 270px;
  }
  .image img {
    position: absolute;
    top: 19%;
    left: 21%;
  }
}

.resultsContainer {
  background-color: #0e0119;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.resultsContainer .resultsDiv {
  background-color: #0e0119;
  height: auto;
  position: relative;
  width: 1440px;
}


.resultsContainer .resultsOverlap {
  height: 1674px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.resultsContainer .resultsRectangle {
  border-radius: 20px;
  height: 456px;
  left: 28px;
  position: absolute;
  top: 28px;
  width: 1384px;
}

.resultsContainer .resultsRectangle.resultsSuccessBackground {  
  background: linear-gradient(180deg, rgb(14, 0, 25) 17.67%, rgb(100, 61, 173) 100%);
}
.resultsContainer .resultsRectangle.resultsFailedBackground {  
  background: linear-gradient(180deg, rgb(14, 0, 25) 20.31%, rgb(255, 109, 120) 100%);
}

.resultsContainer .frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  top: 157px;
  width:100%
}

.resultsContainer .p {
  color: #ffffff;
  font-family: var(--subheading-1-font-family);
  font-size: var(--subheading-1-font-size);
  font-style: var(--subheading-1-font-style);
  font-weight: var(--subheading-1-font-weight);
  letter-spacing: var(--subheading-1-letter-spacing);
  line-height: var(--subheading-1-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 764px;
}

.resultsContainer .looks-like-your {
  align-self: stretch;
  color: #ffffff;
  font-family: "Instrument Sans", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  position: relative;
  text-align: center;
}

.resultsContainer .span {
  font-weight: 500;
}

.resultsContainer .text-wrapper-2 {
  font-style: italic;
  font-weight: 500;
}
.resultsContainer .failedContainer, .resultsContainer .successContainer {
  align-items: center;
  background-color: #ffffff;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) 1;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 80px;
  padding: 36px;
  position: absolute;
  top: 294px;
  width: 1280px;
  z-index: 1;
}

.resultsContainer .text-wrapper-7 {
  align-self: stretch;
  color: var(--textmain-light-mode);
  font-family: "Manrope", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 36px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.resultsContainer .frame-9 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 1193px;
  word-break: break-word;;
}

.resultsContainer .frame-10 {
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(14, 0, 25) 40.58%, rgb(100, 61, 173)) 1;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  opacity: 0.9;
  padding: 24px 18px;
  position: relative;
}

.resultsContainer .frame-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.resultsContainer .colored-checked {
  height: 36px;
  position: relative;
  width: 36px;
}

.resultsContainer .icon-action-check {
  height: 16px;
  left: 7px;
  position: absolute;
  top: 11px;
  width: 21px;
}
.resultsContainer .icon-action-error {
  height: 36px;
  position: relative;
  width: 36.3px;
}

.resultsContainer .text-wrapper-8 {
  align-self: stretch;
  color: var(--textmain-light-mode);
  font-family: var(--detail-font-family);
  font-size: var(--detail-font-size);
  font-style: var(--detail-font-style);
  font-weight: var(--detail-font-weight);
  letter-spacing: var(--detail-letter-spacing);
  line-height: var(--detail-line-height);
  position: relative;
}

.resultsContainer .text-wrapper-9 {
  align-self: stretch;
  color: var(--textmain-light-mode);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  position: relative;
}

.resultsContainer .frame-12 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #beecef;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-bottom: -1px;
  margin-left: -1px;
  padding: 4px 8px;
  position: relative;
}
.resultsContainer .frame-12.success-border {
  border-color: #0BC196;
}
.resultsContainer .frame-12.failed-border {
  border-color: #DF3030;
}

.resultsContainer .text-wrapper-10 {
  color: var(--textmain-light-mode);
  font-family: var(--detail-font-family);
  font-size: var(--detail-font-size);
  font-style: var(--detail-font-style);
  font-weight: var(--detail-font-weight);
  letter-spacing: var(--detail-letter-spacing);
  line-height: var(--detail-line-height);
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.resultsContainer .primary-button-2 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 80px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 8px 18px 8px 24px;
  position: relative;
}

.resultsContainer .text-wrapper-11 {
  color: var(--textmain-light-mode);
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.resultsContainer .text-wrapper-11 .anchor-tag-class{
  color: inherit !important;
  text-decoration: none !important;
}

.resultsContainer .reset {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.resultsContainer .results-navigation-menu {
  height: 89px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.resultsContainer .logo {
  height: 30px !important;
  left: 80px !important;
  position: absolute !important;
  top: 24px !important;
  width: 114px !important;
}

.resultsContainer .text-button-wrapper {
  align-items: flex-start;
  display: inline-flex;
  gap: 36px;
  left: 1249px;
  position: absolute;
  top: 24px;
  cursor: pointer;
}

.resultsContainer .text-button {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  padding: 10px 0px;
  position: relative;
}

.resultsContainer .text-wrapper-12 {
  color: #ffffff;
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.resultsContainer .footer {
  align-items: center;
  background-color: #0e0119;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 0;
  padding: 40px 0px 80px;
  position: relative;
  top: 0px;
  width: 1440px;
}

.resultsContainer .frame-21 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1279.94px;
}

.resultsContainer .frame-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 31px;
  position: relative;
}

.resultsContainer .logo-3 {
  height: 30px !important;
  position: relative !important;
  width: 114px !important;
}

.resultsContainer .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.resultsContainer .img-2 {
  height: 55.53px;
  position: relative;
  width: 55.53px;
}

.resultsContainer .image {
  height: 35.88px;
  position: relative;
  width: 100.9px;
}

.resultsContainer .layer {
  height: 31px;
  position: relative;
  width: 71px;
}

.resultsContainer .frame-24 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.resultsContainer .frame-25 {
  flex: 0 0 auto;
  position: relative;
}

.resultsContainer .text-wrapper-18 {
  color: #ffffff;
  font-family: var(--paragraph-r-font-family);
  font-size: var(--paragraph-r-font-size);
  font-style: var(--paragraph-r-font-style);
  font-weight: var(--paragraph-r-font-weight);
  letter-spacing: var(--paragraph-r-letter-spacing);
  line-height: var(--paragraph-r-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.resultsContainer .frame-26 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 103px;
}

.resultsContainer .made-with-by-raw {
  color: #ffffff;
  font-family: "Alliance No.2-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.resultsContainer .text-wrapper-19 {
  color: #ffffff;
  font-family: var(--paragraph-r-font-family);
  font-size: var(--paragraph-r-font-size);
  font-style: var(--paragraph-r-font-style);
  font-weight: var(--paragraph-r-font-weight);
  letter-spacing: var(--paragraph-r-letter-spacing);
  line-height: var(--paragraph-r-line-height);
}

.resultsContainer .feature-image {
  background: linear-gradient(180deg, rgb(72, 42, 111) 0%, rgb(113, 74, 175) 100%);
  border-radius: 20px;
  left: 52px;
  position: absolute;
  top: 465px;
  width: 1280px;
}

.resultsSummary {
  left: 0;
  position: absolute;
  top: 644px;
  width: 1440px;
}

.control-header-top {
  font-size:16px !important;
}


@media only screen and (max-width: 1440px){
  .resultsContainer {
    padding:0px 20px;
  } 
  .resultsContainer .resultsDiv {
    width: 100%
  }
  .resultsContainer .resultsOverlap {
    display: flex;
    justify-content: center;
    height: 485px;
    left: 0px;
    top: 16px;
    width:100%;
  }
  .resultsContainer .resultsRectangle, .resultsContainer .frame-9, .resultsContainer .frame-2, .resultsContainer .frame-wrapper {
    width: 100%;
  }
  .resultsContainer .overlap-2{
    width: 100%;
    left: unset
  } 
  .resultsContainer .frame-13 {
    width: 96%;
    left: 2%;
  }
  .resultsSummary {
    left: 0;
    position: absolute;
    top: 644px;
    width: 100%;
  }

  .resultsContainer .resultsRectangle {
    background: linear-gradient(180deg, rgb(14, 0, 25) 17.67%, rgb(100, 61, 173) 100%);
    border-radius: 20px;
    height: 382px;
    left: 0px;
    position: absolute;
    top: 16px;
  }
  .resultsContainer .frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 24px;
    position: absolute;
    top: 139px;
  }
  .resultsContainer .results-navigation-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 60px;
  }

  .resultsContainer .logo {
    left: unset !important;
  }
  .resultsContainer .text-button-wrapper {    
    display: contents;
  }
  .resultsContainer .failedContainer, .resultsContainer .successContainer {
    width: 92%;
    left: unset;   
  }
}

  
@media only screen and (max-width: 992px) {  
  .resultsContainer .frame {   
    width: 485px;
  }

  .resultsContainer .looks-like-your {
    font-size: 32px;
  }

  .resultsContainer .span {
    font-family: var(--title-3-mobile-font-family);
    font-size: var(--title-3-mobile-font-size);
    font-style: var(--title-3-mobile-font-style);
    font-weight: var(--title-3-mobile-font-weight);
    letter-spacing: var(--title-3-mobile-letter-spacing);
    line-height: var(--title-3-mobile-line-height);
  }

  .resultsContainer .failedContainer, .resultsContainer .successContainer {
    top: 414px;
    width: 100%;
  }
  .resultsContainer .frame-13 {
    width: 100%;
    left: unset;
    flex-direction: column;
    align-items: flex-start;
  }
  .resultsContainer .frame-16 {
    width : 100%;
  }
  .resultsContainer .frame-14 {
    padding:unset
  }
  .resultsContainer .frame-2 {
    top:840px;
  }
  .resultsContainer .results-navigation-menu {
    padding: 0px 30px;
  }
  .control-header-top {
    display: flex;
  }
}

@media only screen and (max-width: 767px){ 

  .resultsContainer .text-button-wrapper {   
    right:10px;
  }

  .resultsContainer .frame-9 {
    overflow-x: scroll;
  }
  
  .resultsContainer .frame-10 {
    min-width: 200px;
  }
  
  .resultsSummary {
    align-items: flex-start;
    background-color: #0e0119;
    display: flex;
    flex-direction: column;
    gap: 36px;
    left: 0;
    padding: 40px 16px;
    position: absolute;
  }

}
  
@media only screen and (max-width: 540px){ 
  .resultsContainer .frame {   
    width: 100%;
  }
  .resultsContainer .p {
    width: auto
  }
  .resultsContainer .results-navigation-menu {
    padding: 0px 5px;
  }
}
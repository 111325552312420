.external-link {
    height: 16px;
    position: relative;
    width: 16px;
  }
  
.external-link .external-image {
  height: 11px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 11px;
}
  
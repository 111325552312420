body {
  background-color: var(--black);
}
.homeIndex {
  background-color: #0e0119;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.homeIndex .text-body-normal {
  color: var(--textmain-dark-mode);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
}

.homeIndex .container {
  position: relative;
  width: 1440px;
  max-width: 100%;
}

.homeIndex .errorInfo {
  display: flex;
  justify-content: center;
  left: 28px;
  position: relative;
  top: 28px;
  padding: 5px;
  color: #df3030;
  width: 1384px;
}

.homeIndex .overlap-group {
  height: 1023px;
  left: 28px;
  position: relative;
  top: 28px;
  width: 1384px;
}

.homeIndex .rectangle {
  background: linear-gradient(
    180deg,
    rgb(14, 0, 25) 40.58%,
    rgb(100, 61, 173) 100%
  );
  border-radius: 20px;
  height: 723px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1384px;
}

.homeIndex .logo {
  height: 30px !important;
  left: 635px !important;
  position: absolute !important;
  top: 60px !important;
  width: 114px !important;
}

.homeIndex .top-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 382px;
  position: absolute;
  top: 135px;
  width: 620px;
  padding: 0px 5px;
}
.homeIndex .text-heading-2 {
  align-self: stretch;
  color: var(--textheading);
  font-family: var(--title-2-font-family);
  font-size: var(--title-2-font-size);
  font-style: var(--title-2-font-style);
  font-weight: var(--title-2-font-weight);
  letter-spacing: var(--title-2-letter-spacing);
  line-height: var(--title-2-line-height);
  position: relative;
  text-align: center;
  margin: 0;
}

.homeIndex .run-test-button-container {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.homeIndex .primary-button-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.homeIndex .design-component-instance-node {
  flex: 0 0 auto !important;
}

.homeIndex .text-details {
  color: var(--textmain-dark-mode);
  font-family: var(--detail-font-family);
  font-size: var(--detail-font-size);
  font-style: var(--detail-font-style);
  font-weight: var(--detail-font-weight);
  letter-spacing: var(--detail-letter-spacing);
  line-height: var(--detail-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin: 0;
}
.homeIndex .group {
  height: 48px;
  left: 631px;
  position: absolute;
  top: 389px;
  width: 124px;
}
.homeIndex .text-normal {
  color: var(--textmain-dark-mode);
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
}
.homeIndex .text-button-text {
  left: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  margin: 0;
}

.homeIndex .icon-arrow-instance {
  height: 24px;
  left: 49px;
  top: 24px;
  width: 24px;
}

.homeIndex .feature-image {
  background: linear-gradient(
    180deg,
    rgb(72, 42, 111) 0%,
    rgb(113, 74, 175) 100%
  );
  border-radius: 20px;
  left: 52px;
  position: absolute;
  top: 465px;
  width: 1280px;
}

.homeIndex .feature-image video {
  border-radius: 20px;
  margin-bottom: -6px;
}
.homeIndex .parent-middle-container{
  display: flex;
  gap: 100px;
  padding: 80px 0px;
  justify-content: space-around;
  align-items: center;
  left: 5%;
  position: absolute;
  right: 5%;
}
.homeIndex .middle-container {
  align-items: center;
  display: flex;
  gap: 100px;
  left: 0;
}

.homeIndex .middle-container .left-content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 14px;
  position: relative;
}

.homeIndex .text-heading-3 {
  color: var(--textheading);
  font-family: var(--title-3-font-family);
  font-size: var(--title-3-font-size);
  font-style: var(--title-3-font-style);
  font-weight: var(--title-3-font-weight);
  letter-spacing: var(--title-3-letter-spacing);
  line-height: var(--title-3-line-height);
  position: relative;
  width: 100%;
  margin: 0;
}

.homeIndex .middle-container .content-text {
  align-self: stretch;
  position: relative;
  margin: 0;
}

.homeIndex .middle-container .button-container {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 14px;
  position: relative;
}

.homeIndex .middle-container .right-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.homeIndex .text-sub-heading-1 {
  align-self: stretch;
  color: var(--textmain-dark-mode);
  font-family: var(--subheading-1-font-family);
  font-size: var(--subheading-1-font-size);
  font-style: var(--subheading-1-font-style);
  font-weight: var(--subheading-1-font-weight);
  letter-spacing: var(--subheading-1-letter-spacing);
  line-height: var(--subheading-1-line-height);
  margin: 0;
  position: relative;
}

.homeIndex .text-wrapper-list {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.homeIndex .ellipse {
  background-color: #6849e0;
  border-radius: 4px;
  box-shadow: 0px 0px 7px 1px #6849e066;
  height: 8px;
  position: relative;
  width: 8px;
}

.homeIndex .text-wrapper-list-body {
  color: var(--textmain-dark-mode);
  flex: 1;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  margin: 0;
  position: relative;
}
.homeIndex .how-it-works-hash {
  color: inherit;
  text-decoration: none;
}

/* On screens that are 992px or less, set the background color to blue */
@media only screen and (max-width: 1440px) {
  .homeIndex {
    padding: 0px 20px;
    width: 100%;
  }
  .homeIndex .container {
    width: 100%;
    max-width: 100%;
  }
  .homeIndex .overlap-group {
    display: flex;
    justify-content: center;
    height: 485px;
    left: 0px;
    top: 16px;
    width: 100%;
  }
  .homeIndex .errorInfo {
    width: 100%;
    left: 0;
    margin-bottom: 10px;
  }
  .homeIndex .rectangle {
    width: 100%;
  }
  .homeIndex .feature-image {
    width: 92%;
    left: unset;
  }

  .homeIndex .logo {
    left: unset !important;
  }

  .homeIndex .group,
  .homeIndex .group .text-wrapper-2,
  .homeIndex .top-content {
    left: unset;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1200px) {
  .homeIndex .rectangle {
    height: 680px !important;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .homeIndex .rectangle {
    height: 640px;
  }
}

@media only screen and (max-width: 992px) {
  .homeIndex .rectangle {
    height: 485px;
  }
  .homeIndex .feature-image {
    width: 100%;
    top: 522px;
  }
  .homeIndex .text-heading-2 {
    color: #ffffff;
    font-family: var(--title-2-mobile-font-family);
    font-size: var(--title-2-mobile-font-size);
    font-style: var(--title-2-mobile-font-style);
    font-weight: var(--title-2-mobile-font-weight);
    letter-spacing: var(--title-2-mobile-letter-spacing);
    line-height: var(--title-2-mobile-line-height);
    margin-top: -1px;
  }
  .homeIndex .text-heading-3 {
    align-self: stretch;
    color: #ffffff;
    font-family: var(--title-3-mobile-font-family);
    font-size: var(--title-3-mobile-font-size);
    font-style: var(--title-3-mobile-font-style);
    font-weight: var(--title-3-mobile-font-weight);
    letter-spacing: var(--title-3-mobile-letter-spacing);
    line-height: var(--title-3-mobile-line-height);
    margin-top: -1px;
  }
  .homeIndex .top-content {
    width: 520px;
  }
}

@media only screen and (max-width: 767px) {
  .homeIndex .middle-container {
    display: block;
  }
  .homeIndex .middle-container .right-content {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 540px) {
  .homeIndex .top-content {
    width: auto;
  }
}

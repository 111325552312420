.summary {
  align-items: center;
  background-color: #0e0119;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  padding-top: 120px;
  position: absolute;
  top: 0;
  width: 1440px;
}

.summary-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.summary-text-wrapper-3 {
  color: #ffffff;
  font-family: "Instrument Sans", Helvetica;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 44px;
  position: relative;
  text-align: center;
  width: 722px;
}

.summary-text-wrapper-4 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  position: relative;
  text-align: center;
}

.summary-wrapper {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 1280px;
}

.div-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 34px;
  position: relative;
  width: 100%;
}

.summary-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.summary-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.summary-6 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #0e0119;
  border: 1px solid rgba(255, 255, 255, .4);
  border-color: rgba(255, 255, 255, .4) rgba(255, 255, 255, .15) rgba(255, 255, 255, .25) rgba(255, 255, 255, .35);
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  padding: 30px 24px;
  position: relative;
}

.summary .image-small {
  height: 24px;
  position: relative;
  width: 24px;
}

.summary .image-medium {
  height: 48px;
  position: relative;
}

.summary-text-wrapper-5 {
  align-self: stretch;
  color: var(--textmain-dark-mode);
  font-family: "Instrument Sans", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  position: relative;
}

.summary-text-wrapper-6 {
  align-self: stretch;
  color: var(--textmain-dark-mode);
  font-family: "Manrope", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
}

.capability-icons {
  height: 32px;
  position: relative;
  width: 47px;
}

.overlap-group {
  /* height: 22px; */
  position: relative;
  /* top: 13px; */
}

.REGO {
  height: 10px;
  left: 7px;
  position: absolute;
  top: 6px;
  width: 32px;
  /* border: 1px solid;
  border-color: #ffffff; */
}
/* .REGO { 
  height: 32px;
  position: relative;
  width: 36px;
} */

.rectangle-2 {
  border: 1px solid;
  border-color: #ffffff;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 47px;
}

.summary-7 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #0e0119;
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    )
    1;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 30px 24px;
  position: relative;
  width: 293px;
}

.summary .failedContainer-frame-3 {
  /* align-items: flex-start; */
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 976px;
}

.summary .div-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  position: relative;
}

.summary .failedContainer-text-wrapper-3 {
  color: var(--textheading);
  font-family: var(--title-3-font-family);
  font-size: var(--title-3-font-size);
  font-style: var(--title-3-font-style);
  font-weight: var(--title-3-font-weight);
  letter-spacing: var(--title-3-letter-spacing);
  line-height: var(--title-3-line-height);
  margin-top: -1px;
  position: relative;
}

.summary .failedContainer-text-wrapper-4 {
  color: var(--textmain-light-mode);
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.summary .failedContainer-frame-4 {
  align-items: flex-start;
  border-radius: 20px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 985px;
  padding-bottom: 90px;
}

.summary .failedContainer-frame-5 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #ffffff33;
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  opacity: 0.9;
  padding: 24px 18px;
  position: relative;
  width: 100%;
}

.summary .failedContainer-frame-6 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  min-width: 30%;
}

.summary .failedContainer-frame-7 {
  height: 36px;
  position: relative;
  width: 36.3px;
}

.summary .failedContainer-frame-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.summary .failedContainer-frame-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.summary .failedContainer-text-wrapper-5 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
}

.summary .failedContainer-text-wrapper-6 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--paragraph-l-font-family);
  font-size: var(--paragraph-l-font-size);
  font-style: var(--paragraph-l-font-style);
  font-weight: var(--paragraph-l-font-weight);
  letter-spacing: var(--paragraph-l-letter-spacing);
  line-height: var(--paragraph-l-line-height);
  position: relative;
}

.summary .failedContainer-frame-10 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #DF3030;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  margin-bottom: -1px;
  margin-left: -1px;
  padding: 4px 8px;
  position: relative;
}

.summary .failedContainer-text-wrapper-7 {
  color: #ffffff;
  font-family: var(--detail-font-family);
  font-size: var(--detail-font-size);
  font-style: var(--detail-font-style);
  font-weight: var(--detail-font-weight);
  letter-spacing: var(--detail-letter-spacing);
  line-height: var(--detail-line-height);
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.summary .failedContainer-frame-11 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #22152d;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  padding: 24px;
  position: relative;
  flex-direction: column;
  font-weight: 600;
  font-size: 16px; 
  word-break: break-word;
}
.summary .failedContainer-result-header{
  color: #fff;
  font-size: 600;
}

.summary .failedContainer-text-wrapper-8 {
  color: #ffffff;
  flex: 1;
  font-family: "IBM Plex Mono", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
}

.summary .failedContainer-text-wrapper-9 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--detail-font-family);
  font-size: var(--detail-font-size);
  font-style: var(--detail-font-style);
  font-weight: var(--detail-font-weight);
  letter-spacing: var(--detail-letter-spacing);
  line-height: var(--detail-line-height);
  margin-top: -1px;
  position: relative;
}

.summary .failedContainer-text-wrapper-10 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  position: relative;
}

.summary .failedContainer-links {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

@media only screen and (max-width: 1440px) {
  .summary {
    width: 100%;
  }
  .summary .failedContainer-text-wrapper-3 {
    width: 100%;
  }
  .summary-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .summary {
    top: 50px;
  }
}

@media only screen and (max-width: 1048px) {
  .summary .failedContainer-frame-4 {
    width: auto;
  }
  .summary .failedContainer-frame-3 {
    width: 95%;
  }
}
@media only screen and (max-width: 992px) {
  .summary {
    top: 200px;
  }
  .summary .failedContainer-frame-3 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
  }
  .summary .failedContainer-text-wrapper-3 {
    text-align: center;
  }
  .summary-5 {
    flex-flow: column nowrap;
  }
  .summary .failedContainer-frame-4 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .summary .failedContainer-text-wrapper-3 {
    align-self: stretch;
    color: #ffffff;
    font-family: var(--title-3-mobile-font-family);
    font-size: var(--title-3-mobile-font-size);
    font-style: var(--title-3-mobile-font-style);
    font-weight: var(--title-3-mobile-font-weight);
    letter-spacing: var(--title-3-mobile-letter-spacing);
    line-height: var(--title-3-mobile-line-height);
    margin-top: -1px;
    position: relative;
  }

  .summary .failedContainer-links {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
  }
  .summary-5 {
    flex-direction: column;
  }
  .summary-3 {
    width: 100%;
  }
  .summary-text-wrapper-3 {
    width: auto;
  }
}
@media only screen and (max-width: 524px) {
  .summary .failedContainer-frame-5 {
    align-items: flex-start;
    flex-direction: column;
  }
  .summary .failedContainer-frame-3 {
    width: 100%;
  }
}

body {
  background-color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.index .text-body-normal {
  color: var(--textmain-dark-mode);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
}
.index .text-normal {
  color: var(--textmain-dark-mode);
  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-style: var(--button-font-style);
  font-weight: var(--button-font-weight);
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
}
.index .text-button-text {
  left: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  margin: 0;
}
.index .text-heading-2 {
  align-self: stretch;
  color: var(--textheading);
  font-family: var(--title-2-font-family);
  font-size: var(--title-2-font-size);
  font-style: var(--title-2-font-style);
  font-weight: var(--title-2-font-weight);
  letter-spacing: var(--title-2-letter-spacing);
  line-height: var(--title-2-line-height);
  position: relative;
  text-align: center;
  margin: 0;
}
p {
  margin: 0;
}

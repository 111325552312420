
.contact-us {
    background: linear-gradient(180deg, rgb(14, 0, 25) 40.58%, rgb(100, 61, 173) 100%);
    border-radius: 20px;
    height: 749px;
    /* left: 28px; */
    position: relative;
    /* top: 1674px; */
    width: 1384px;
  }
  
  .contact-us .contact-us-frame-13 {
    align-items: center;
    background-color: #ffffff;
    /* border: 1px solid; */
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) 1;
    border-radius: 20px;
    display: flex;
    gap: 36px;
    left: 52px;
    padding: 36px;
    position: relative;
    top: 55px;
    width: 1280px;
  }
  
  .contact-us .contact-us-frame-14 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    padding: 0px 0px 0px 30px;
    position: relative;
  }
  
  .contact-us .contact-us-text-wrapper-13 {
    align-self: stretch;
    color: var(--black);
    font-family: "Instrument Sans", Helvetica;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1px;
    position: relative;
  }
  
  .contact-us .contact-us-frame-15 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    position: relative;
    width: 100%;
  }
  
  .contact-us .ellipse {
    background-color: #6849e0;
    border-radius: 4px;
    box-shadow: 0px 0px 7px 1px #6849e066;
    height: 8px;
    position: relative;
    width: 8px;
  }
  
  .contact-us .contact-us-text-wrapper-14 {
    color: var(--textmain-light-mode);
    flex: 1;
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    font-style: var(--body-font-style);
    font-weight: var(--body-font-weight);
    letter-spacing: var(--body-letter-spacing);
    line-height: var(--body-line-height);
    margin-top: -1px;
    position: relative;
  }
  
  .contact-us .contact-us-frame-16 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 539px;
  }
  
  .contact-us .contact-us-frame-17 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .contact-us .contact-us-text-wrapper-15 {
    align-self: stretch;
    color: #0e0119;
    font-family: var(--subheading-1-font-family);
    font-size: var(--subheading-1-font-size);
    font-style: var(--subheading-1-font-style);
    font-weight: var(--subheading-1-font-weight);
    letter-spacing: var(--subheading-1-letter-spacing);
    line-height: var(--subheading-1-line-height);
    margin-top: -1px;
    position: relative;
  }
  
  .contact-us .contact-us-frame-18 {
    align-self: stretch;
    /* border: 1px solid;
    border-color: #d0d0d0; */
    border-radius: 8px;
    height: 48px;
    position: relative;
    width: 100%;
 
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  .contact-us .contact-us-frame-18:focus { 
    outline: none !important;
    /* border-color: #c41dff; */
    box-shadow: 0px 0px 5px #c41dff;
 }
 .contact-us .contact-us-frame-18-error {
  border-color : red
 }
  .contact-us .contact-us-frame-18-error:focus { 
    outline: none !important;
    /* border-color: #c41dff; */
    box-shadow: 0px 0px 5px red;
 }
  
  .contact-us .contact-us-frame-19 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 144px;
    position: relative;
    width: 100%;
  }
  
  .contact-us .contact-us-frame-20 {
    align-self: stretch;
    border: 1px solid;
    border-color: #d0d0d0;
    border-radius: 8px;
    flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }
  
  .contact-us .contact-us-primary-button-instance {
    flex: 0 0 auto !important;
  }
  
  .contact-us .contact-us-never-share {
    align-self: stretch;
    color: #0e0119;
    font-family: "Instrument Sans", Helvetica;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 12.9px;
    position: relative;
  }
  
  .contact-us .contact-us-text-wrapper-16 {
    color: #0e0119;
    font-family: "Instrument Sans", Helvetica;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 12.9px;
  }
  
  .contact-us .contact-us-text-wrapper-17 {
    text-decoration: underline;
  }
 
  @media only screen and (max-width: 1440px){  
    .contact-us {
      width:100%
    }
    .contact-us-frame-16 {
      width: auto
    }
    .contact-us .contact-us-frame-13 {
      width: auto;
      left:unset
    }
  }
  
@media only screen and (max-width: 992px){ 
  .contact-us {
    height: auto
  }
  .contact-us .contact-us-frame-13, .contact-us .contact-us-frame-14, .contact-us .contact-us-frame-16 {
    width: 100%
  }
  .contact-us .contact-us-frame-13 {
    flex-direction: column;
    z-index: 10;
  }
  .contact-us .contact-us-frame-14 {
    padding: 0px;
  }
}


.CTA-banner-results {
  height: 529px;
  width: 1384px;
}

.CTA-banner-results-instance {
  left: 40px !important;
  position: absolute !important;
  top: 1446px !important;
}

.CTA-banner-results .CTA-banner-results-frame {
  align-items: center;
  background: linear-gradient(180deg, rgb(14, 0, 25) 0%, rgb(38.02, 17.04, 66.34) 45.34%, rgb(100, 61, 173) 100%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 200px 40px;
  position: relative;
  width:100%
}

.CTA-banner-results .CTA-banner-results-div {
  color: var(--textmain-dark-mode);
  font-family: var(--title-3-font-family);
  font-size: var(--title-3-font-size);
  font-style: var(--title-3-font-style);
  font-weight: var(--title-3-font-weight);
  letter-spacing: var(--title-3-letter-spacing);
  line-height: var(--title-3-line-height);
  text-align: center;
  margin-top: -0.5px;
}

.CTA-banner-results .primary-button-instance {
  flex: 0 0 auto !important;
}
  
@media only screen and (max-width: 1440px){
  .CTA-banner-results {
    width: 100%
  }
  .CTA-banner-results-instance {
    top: 1346px !important;
    left: unset !important;
    width: 100%;
  }
}

@media only screen and (max-width: 767px){
  .CTA-banner-results .CTA-banner-results-div {    
    font-family: var(--title-3-mobile-font-family);
    font-size: var(--title-3-mobile-font-size);
    font-style: var(--title-3-mobile-font-style);
    font-weight: var(--title-3-mobile-font-weight);
    letter-spacing: var(--title-3-mobile-letter-spacing);
    line-height: var(--title-3-mobile-line-height);
    text-align: center;
  }
}

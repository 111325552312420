.primary-button {
    align-items: center;
    border-radius: 80px;
    display: inline-flex;
    gap: 4px;
    padding: 8px 18px 8px 24px;
    position: relative;
    cursor: pointer;
  }
  
  .primary-button .request-demo {
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    font-style: var(--button-font-style);
    font-weight: var(--button-font-weight);
    letter-spacing: var(--button-letter-spacing);
    line-height: var(--button-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .primary-button .icon-arrow {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }
  
  .primary-button.inverse-false {
    background-color: #ffffff;
  }
  
  .primary-button.hover {
    box-shadow: 0px 0px 20px #c41dff;
  }
  
  .primary-button.inverse-true {
    background-color: #643dad;
  }
  
  .primary-button.inverse-false .request-demo {
    color: var(--textmain-light-mode);
  }
  
  .primary-button.inverse-true .request-demo {
    color: var(--textmain-dark-mode);
  }
  